import styled from "styled-components";

interface ButtonProps {
  width?: string;
  height?: string;
  margin?: string;
  fontSize?: string;
  mobileHeight?: string;
  mobilewidth?: string;
  mobileMargin?: string;
  mobileFontSize?: string;
  svghHeight?: string;
  mobileSvgHeight?: string;
  background?: string;
  marginText?: string;
  fontColor?: string;
  fontWeight?: string;
  border?: string;
}

export const SeeHowButtonContainer = styled.div<ButtonProps>`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "152.68px")};
  height: ${(props) => (props.height ? props.height : "55.52px")};
  background: ${(props) =>
    props.background ||
    "linear-gradient(270deg,#f93d66 -77.99%,rgba(249, 61, 102, 0) 100%)"};
  border: ${(props) => props.border || "0.5px solid rgba(249, 61, 102, 0.51)"};
  border-radius: 39px;
  margin: ${(props) => props.margin || "32px 0px"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize || "16px"};
  line-height: 14px;
  cursor: pointer;
  .text-sec {
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 400};
    font-size: ${(props) => props.fontSize || "16px"};
    line-height: 14px;
    text-align: center;
    color: ${(props) => props.fontColor || "#ffffff"};
    width: 80%;
    margin: ${(props) => props.marginText};

    @media (max-width: 720px) {
      font-size: ${(props) =>
        props.mobileFontSize
          ? props.mobileFontSize
          : props.fontSize
          ? props.fontSize
          : "16px"};
    }
  }

  .arrow-container {
    display: flex;
    align-items: center;
  }

  svg {
    height: ${(props) => props.svghHeight};
    /* margin-top: 3px; */
    transform: translate(2px, -0.25px);
    @media (max-width: 720px) {
      height: ${(props) => props.mobileSvgHeight || props.svghHeight};
    }
  }
  @media (max-width: 720px) {
    width: ${(props) =>
      props.mobilewidth
        ? props.mobilewidth
        : props.width
        ? props.width
        : "152.68px"};
    height: ${(props) =>
      props.mobileHeight
        ? props.mobileHeight
        : props.height
        ? props.height
        : "55.52px"};
    margin: ${(props) =>
      props.mobileMargin
        ? props.mobileMargin
        : props.margin
        ? props.margin
        : "32px 0px"};
  }
`;
