import styled from "styled-components";

export const LargeText = styled.div<any>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: ${(props) => props.textColor || "#ffffff"};
  @media screen and (min-width: 1060px) and (max-width: 1260px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media screen and (min-width: 520px) and (max-width: 992px) {
    font-size: 50px;
    line-height: 59px;
  }

  @media screen and (min-width: 380px) and (max-width: 520px) {
    font-size: 34px;
    line-height: 40px;
  }

  @media screen and (min-width: 280px) and (max-width: 380px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const SmallText = styled.div<any>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.03em;
  color: ${(props) => props.textColor || "#ffffff"};
  @media screen and (min-width: 1060px) and (max-width: 1260px) {
    font-size: 36px;
    line-height: 48px;
  }
  @media screen and (min-width: 520px) and (max-width: 992px) {
    font-size: 28px;
    line-height: 37px;
  }
  @media screen and (min-width: 380px) and (max-width: 520px) {
    font-size: 25px;
    line-height: 34px;
  }

  @media screen and (min-width: 280px) and (max-width: 380px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

export const DynamicLargeText = styled.div<any>`
  ${(props) => props.styles}
  margin: ${(props) => props.margin};
`;

export const DynamicSmallText = styled.div<any>`
  ${(props) => props.styles}
`;
