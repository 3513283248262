import gsap from "gsap";
import anime from "animejs";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useIsomorphicLayoutEffect } from "../../../pages";
import { LandingPageTextAnimationContainer } from "./styles";
import { useRouter } from "next/router";

gsap.registerPlugin(ScrollTrigger);

interface LandingPageTextAnimationProps {
  firstLine?: string;
  secondLine?: string;
  thirdLine?: string;
  fourthLine?: string;
  textAnimationClassName: string;
  textContainerClassName: string;
  largeTextFontSize?: string;
  smallTextFontSize?: string;
  largeTextLineHeight?: string;
  smallTextLineHeight?: string;
  margin?: string;
  idMargin?: string;
}

export const localeToFontClassMap: any = {
  en: "en",
  id: "id",
  "id-ID": "en",
  "ja-JP": "en",
  jp: "jp",
  us: "en",
  row: "en",
};

export const LandingPageTextAnimation = (
  props: LandingPageTextAnimationProps
) => {
  const router = useRouter();

  // font class mapping
  const normalFontClass = `normal-font-${
    localeToFontClassMap[router.locale || ""]
  }`;
  const boldFontClass = `bold-font-${
    localeToFontClassMap[router.locale || ""]
  }`;

  const localeMargin =
    router.locale === "id" || router.locale === "jp"
      ? props.idMargin
      : props.margin;

  const textToHtml = (text: string = "", isFirstLine: boolean = false) => {
    let tokens: any[] = (text || "").split("\n");
    let charArray: any[] = [];
    tokens = tokens.map((token) => {
      let isBold = startsAndEndsWithAsterisk(token.trim());
      if (isBold) {
        token
          .trim()
          .split("*")
          .join("")
          .split("")
          .map((char: string) => {
            charArray.push({ value: char, isBold: true });
          });
      } else {
        token.split("").map((char: string) => {
          charArray.push({ value: char, isBold: false });
        });
      }
      charArray.push({ value: " ", isBold: false });
    });

    return splitText(charArray, isFirstLine);
  };

  const splitText = (text: any[], isFirstLineChars: boolean = false) => {
    return text.map((textChar, index: any) => {
      let res =
        textChar.value === " "
          ? `<span class="${props.textAnimationClassName} ${
              isFirstLineChars ? `first-line-${router.locale}` : "test"
            }  animation-span" style="letter-spacing:4px;">&nbsp;</span>`
          : `<span class="${props.textAnimationClassName} ${
              isFirstLineChars ? `first-line-${router.locale}` : "test"
            } animation-span ${
              textChar.isBold ? boldFontClass : normalFontClass
            } " >${textChar.value}</span>`;
      return res;
    });
  };

  function startsAndEndsWithAsterisk(str: string) {
    if (str.length < 2) {
      return false;
    }
    const firstChar = str[0];
    const lastChar = str[str.length - 1];
    if (firstChar === "*" && lastChar === "*") {
      return true;
    } else {
      return false;
    }
  }

  let processedFirstLine: string = "";
  let processedSecondLine: string = "";
  let processedThirdLine: string = "";
  let processedFourthLine: string = "";

  if (props.firstLine) {
    processedFirstLine = textToHtml(
      props.firstLine?.replaceAll("<br>", "\n"),
      true
    ).join("");
  }

  if (props.secondLine) {
    processedSecondLine = textToHtml(
      props.secondLine?.replaceAll("<br>", "\n")
    ).join("");
  }

  if (props.thirdLine) {
    processedThirdLine = textToHtml(
      props.thirdLine?.replaceAll("<br>", "\n")
    ).join("");
  }

  if (props.fourthLine) {
    processedFourthLine = textToHtml(
      props.fourthLine?.replaceAll("<br>", "\n")
    ).join("");
  }

  useIsomorphicLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.set(`.${props.textAnimationClassName}`, {
        opacity: 0,
      });
      ScrollTrigger.create({
        trigger: `.${props.textContainerClassName}`,
        start: "top bottom",
        end: `+50%`,
        invalidateOnRefresh: true,
        onEnter: () => {
          anime.timeline({ loop: false }).add({
            targets: `.${props.textAnimationClassName}`,
            translateX: [40, 0],
            translateZ: 0,
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 600,
            markers: true,
            delay: (el, i) => 350 + 10 * i,
          });
        },
      });
    });

    return () => {
      context.revert();
    };
  }, []);

  return (
    <LandingPageTextAnimationContainer
      className={`hide ${props.textContainerClassName}`}
      style={{ margin: localeMargin }}
    >
      {processedFirstLine && (
        <div dangerouslySetInnerHTML={{ __html: processedFirstLine }}></div>
      )}

      {processedSecondLine && (
        <div dangerouslySetInnerHTML={{ __html: processedSecondLine }}></div>
      )}

      {processedThirdLine && (
        <div dangerouslySetInnerHTML={{ __html: processedThirdLine }}></div>
      )}

      {processedFourthLine && (
        <div dangerouslySetInnerHTML={{ __html: processedFourthLine }}></div>
      )}
    </LandingPageTextAnimationContainer>
  );
};
