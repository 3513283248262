/* eslint-disable @next/next/no-img-element */
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useIsomorphicLayoutEffect } from "../../../pages";
import {
  BannerBackground,
  CategoriesDesktopContainer,
  Content,
  LeftWidgetContainer,
  LockscreenImage,
  MobileOutline,
  Ribbon,
  RibbonContainer,
  RightWidgetContainer,
  // RightWidgetContainer,
  TimeSection,
  WidgetContainer,
} from "./styles";
import { useRouter } from "next/router";

interface LockscreenCardsProps {
  data: CategoryData[];
}

interface CategoryData {
  mobileBackground: string;
  desktopBackground: string;
  desktopLsImage: string;
  leftWidgetUrl: string;
  rightWidgetUrl: string;
  title: string;
  desktopLsImageAltText: string;
  desktopMobileOutline: string;
}

const textAnimation: any = {
  enBold: ` position: absolute;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0;
  margin-top: 13vh;

  @media screen and (min-width: 960px) and (max-width: 1300px) {
    font-size: 62px;
  }

  @media screen and (min-width: 720px) and (max-width: 960px) {
    font-size: 52px;
  }

  @media screen and (min-width: 300px) and (max-width: 720px) {
    font-size: 58px;
  }`,
  idBold: `
  position: absolute;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 62px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0;
  margin-top: 13vh;

  @media screen and (min-width: 960px) and (max-width: 1300px) {
    font-size: 42px;
  }

  @media screen and (min-width: 720px) and (max-width: 960px) {
    font-size: 43px;
  }

  @media screen and (min-width: 300px) and (max-width: 720px) {
    font-size: 38px;
  }
  `,
  jpBold: `
  position: absolute;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0;
  margin-top: 13vh;

    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 26px;
      line-height: 34px;
    }
  `,
};

export const textAnimationCSSMapper: any = {
  id: "id",
  "id-ID": "en",
  "ja-JP": "en",
  jp: "jp",
};

/*
This component integrates four animations:
  1) The mobile lockscreen layer displays the real-time.
  2) While scrolling, the background undergoes a fade-in and fade-out animation.
  3) The mobile lockscreen layer also changes dynamically with scrolling.
  4) Additionally, the bottom widgets smoothly rotate cubically as you scroll.
  5) and content will change dynamically with scrolling.
*/

export const CategoriesDesktop = (props: LockscreenCardsProps) => {
  let i = 0;
  let flag = "clock-face-front-desktop";
  let flag2 = "clock-face-front-right-desktop";

  const usDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const usMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const router: any = useRouter();

  useIsomorphicLayoutEffect(() => {
    let activeIndex = i;
    i++;
    let testFlag = flag;
    if (
      router.locale !== "id" &&
      router.locale !== "id-ID" &&
      router.locale != "ja-JP" &&
      router.locale != "jp"
    ) {
      rotateLeftWidget(
        testFlag,
        2,
        props.data[0].leftWidgetUrl,
        0,
        activeIndex + 1
      );
      rotateRightWidget(
        flag2,
        2,
        props.data[0].rightWidgetUrl,
        0,
        activeIndex + 1
      );
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    const bannerBackgrounds: any[] = gsap.utils.toArray(".background-banner");
    const lockscreenCards: any[] = gsap.utils.toArray(".lockscreen-card");
    const contentCards: any[] = gsap.utils.toArray(".content-card");
    let timeline = gsap.timeline({ paused: true });

    let context = gsap.context(() => {
      const matchMedia = gsap.matchMedia();
      const handleMatchMedia = (mediaContext: any) => {
        const { isDesktop } = mediaContext.conditions;
        if (isDesktop) {
          let ribbons: any[] = gsap.utils.toArray(".ribbon-img");

          for (let i = 1; i < ribbons.length; i++) {
            gsap.set(ribbons[i], {
              opacity: 0,
              transform: "translateX(-10vw)",
              duration: 0,
            });
          }
          for (let i = 1; i < lockscreenCards.length; i++) {
            gsap.set(lockscreenCards[i], {
              opacity: 0,
            });
          }

          timeline
            .set(contentCards[0], {
              opacity: 1,
              duration: 0,
            })
            .set(bannerBackgrounds[0], {
              opacity: 1,
              duration: 0,
            });

          timeline.to(bannerBackgrounds[0], {
            duration: 1,
          });

          props.data.forEach((card, index) => {
            if (index != props.data.length - 1) {
              timeline
                .to(bannerBackgrounds[index + 1], {
                  duration: 0.5,
                  opacity: 1,
                })
                .to(bannerBackgrounds[index], {
                  duration: 1,
                  opacity: 0,
                  ease: "none",
                  delay: 0.5,
                })
                .to(lockscreenCards[index], {
                  duration: 1,
                  opacity: 0,
                  ease: "none",
                  delay: -1,
                })
                .to(lockscreenCards[index + 1], {
                  duration: 1,
                  opacity: 1,
                  delay: -1,
                })
                .to(contentCards[index], {
                  opacity: 0,
                  duration: 0.5,
                  delay: -1,
                })
                .to(contentCards[index + 1], {
                  opacity: 1,
                  duration: 0.5,
                  delay: -0.5,
                  onStart: () => {
                    let idx = index;
                    let activeIndex = i;
                    i++;
                    let testFlag = flag;
                    if (
                      router.locale !== "id" &&
                      router.locale !== "id-ID" &&
                      router.locale != "ja-JP" &&
                      router.locale != "jp"
                    ) {
                      rotateLeftWidget(
                        testFlag,
                        2,
                        props.data[idx + 1].leftWidgetUrl,
                        idx + 1,
                        activeIndex + 1
                      );
                      rotateRightWidget(
                        flag2,
                        2,
                        props.data[idx + 1].rightWidgetUrl,
                        idx + 1,
                        activeIndex + 1
                      );
                    } else {
                      gsap.to(ribbons[index], {
                        opacity: 0,
                        duration: 0.5,
                      });
                      gsap.to(ribbons[index], {
                        transform: "translateX(-10vw)",
                        duration: 0,
                      });
                      gsap.to(ribbons[index + 1], {
                        opacity: 1,
                        transform: "translateX(0vw)",
                        duration: 0.5,
                      });
                    }
                  },
                  onReverseComplete: () => {
                    let testFlag = flag;
                    let idx = index;
                    let activeIndex = i;
                    i++;
                    if (
                      router.locale !== "id" &&
                      router.locale !== "id-ID" &&
                      router.locale != "ja-JP" &&
                      router.locale != "jp"
                    ) {
                      rotateLeftWidget(
                        testFlag,
                        2,
                        props.data[idx].leftWidgetUrl,
                        idx,
                        activeIndex + 1
                      );
                      rotateRightWidget(
                        flag2,
                        2,
                        props.data[idx].rightWidgetUrl,
                        idx,
                        activeIndex + 1
                      );
                    } else {
                      gsap.to(ribbons[index], {
                        opacity: 1,
                        transform: "translateX(0vw)",
                        duration: 0.5,
                      });
                      gsap.to(ribbons[idx + 1], {
                        opacity: 0,
                        duration: 0.5,
                      });
                      gsap.to(ribbons[idx + 1], {
                        transform: "translateX(-10vw)",
                        duration: 0.5,
                      });
                    }
                  },
                });
            }
          });

          timeline.to(contentCards[0], {
            duration: 1,
          });
          ScrollTrigger.create({
            trigger: ".categories-desktop-container",
            start: "top top",
            end: "+=600%",
            pin: true,
            // pinType: "fixed",
            scrub: true,
            invalidateOnRefresh: true,
            animation: timeline,
          });
        } else {
          matchMedia.revert();
        }
      };

      matchMedia.add(
        {
          isMobile: `(max-width: 1060px)`,
          isDesktop: `(min-width: 1060px)`,
        },
        handleMatchMedia
      );
    });

    return () => {
      context.revert();
    };
  }, []);

  const timeDate = () => {
    let date = new Date();
    let hours: any = date.getHours();
    let day = date.getDay();
    let month = date.getMonth();
    let exactDate = date.getDate();
    let dateToBeShown = "";
    let timeTobeShown = "";
    let minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let isAM = hours < 12;
    if (router.locale === "us") {
      if (hours > 12) {
        hours -= 12;
      } else if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;

      if (date.getHours() > 12) {
        isAM = false;
      }

      dateToBeShown = usDays[day] + ", " + usMonths[month] + " " + exactDate;
      timeTobeShown = hours + ":" + minutes;
    } else {
      hours = hours < 10 ? "0" + hours : hours;
      dateToBeShown = days[day] + ", " + exactDate + " " + months[month];
      timeTobeShown = hours + ":" + minutes;
    }

    return (
      <>
        <div className="time">{timeTobeShown}</div>
        <div className="date">{dateToBeShown}</div>
      </>
    );
  };

  const generateBackgroundBanners = () => {
    return props.data.map((card, index) => {
      return (
        <BannerBackground
          key={index}
          className="background-banner"
          background={card.desktopBackground}
          zIndex={props.data.length - index}
        ></BannerBackground>
      );
    });
  };

  const generateLockscreenCards = () => {
    return props.data.map((card, index) => {
      return (
        <LockscreenImage
          key={index}
          className="lockscreen-card"
          zIndex={99 + props.data.length - index}
        >
          <source
            srcSet={card.desktopLsImage}
            type="image/webp"
            media="(min-width: 1060px)"
          />
          <img
            src={
              "https://web.glance-cdn.com/images/glance-website/corp/lockscreen/cat-cards/sports-lat.webp"
            }
            alt={card.desktopLsImageAltText}
          />
        </LockscreenImage>
      );
    });
  };

  const generateContent = () => {
    return props.data.map((card: CategoryData, index) => {
      return (
        <Content
          className={"content-card"}
          key={index}
          styles={
            textAnimation[
              `${textAnimationCSSMapper[router.locale] || "en"}Bold`
            ]
          }
          zIndex={150 + props.data.length - index}
        >
          {card.title}
        </Content>
      );
    });
  };

  function rotateLeftWidget(
    secFace: any,
    lastSec: any,
    bgUrl: any,
    index: any,
    rotationIdx: any
  ) {
    let prevData: any = "";
    let test = gsap.utils.selector(`#clock-seconds-desktop`);
    let test2 = test(`.${secFace}`);
    prevData = test2[0].getAttribute("data-prev");
    /* get id of next/prev face using "data-prev" then update the cube face */
    let tes = test(`.${prevData}`);
    tes[0].style.background = `url(${bgUrl})`;
    tes[0].style.backgroundSize = "cover";
    tes[0].style.backgroundPosition = "center";
    tes[0].style.backgroundRepeat = "no-repeat";
    gsap.to("#clock-seconds-desktop", {
      rotationX: `+${rotationIdx * 90}`,
      duration: 0.5,
    });
    flag = prevData;
  }

  function rotateRightWidget(
    secFace: any,
    lastSec: any,
    bgUrl: any,
    index: number,
    rotationIdx: any
  ) {
    let prevData: any = "";
    let test = gsap.utils.selector(`#clock-seconds-right-desktop`);
    let test2 = test(`.${secFace}`);
    prevData = test2[0].getAttribute("data-prev");

    let tes = test(`.${prevData}`);
    tes[0].style.background = `url(${bgUrl})`;
    tes[0].style.backgroundSize = "cover";
    tes[0].style.backgroundPosition = "center";
    tes[0].style.backgroundRepeat = "no-repeat";
    gsap.to("#clock-seconds-right-desktop", {
      rotationX: `+${rotationIdx * 90}`,
      duration: 0.5,
    });
    flag2 = prevData;
  }

  const generateIDRibbons = () => {
    const idRibbons = [
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat1-ribbon.png",
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat2-ribbon.png",
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat3-ribbon.png",
      "https://web.glance-cdn.com/images/glance-website/corp/id/lockscreen/ribbon/cat4-ribbon.png",
    ];

    return props.data.map((url: CategoryData, index: number) => {
      return (
        <Ribbon className="ribbon-img" src={url.leftWidgetUrl} key={index} />
      );
    });
  };

  return (
    <CategoriesDesktopContainer className="categories-desktop-container">
      {/* Generate Blurred backgrounds */}
      {generateBackgroundBanners()}
      <MobileOutline
        src={props.data[0]?.desktopMobileOutline}
        srcSet="https://web.glance-cdn.com/images/glance-website/corp/lockscreen/cat-cards/sports-lat.webp 1060w, https://web.glance-cdn.com/images/glance-website/corp/lockscreen/desktop/value-props/mobile-outline.png 4000w"
      />
      {/* Generate Mobile Lockscreen layers */}
      {generateLockscreenCards()}

      {/* Generate right side content */}
      {generateContent()}

      {/* Time component */}
      <TimeSection>
        <img className="lock-img" src="/lock.png"></img>
        <div className="time-detail">{timeDate()}</div>
      </TimeSection>

      {/* Generate Widgets */}
      {router.locale !== "id" &&
      router.locale !== "id-ID" &&
      router.locale != "ja-JP" &&
      router.locale != "jp" ? (
        <WidgetContainer className="widget-container-test-desktop">
          <LeftWidgetContainer>
            <div id="content-container-header-clock-desktop">
              <div id="clock-seconds-desktop">
                <div
                  className="clock-face-desktop clock-face-front-desktop"
                  data-next="clock-face-top-desktop"
                  data-prev="clock-face-bottom-desktop"
                ></div>
                <div
                  className="clock-face-desktop clock-face-bottom-desktop"
                  data-next="clock-face-front-desktop"
                  data-prev="clock-face-back-desktop"
                ></div>
                <div className="clock-face-desktop clock-face-left-desktop"></div>
                <div className="clock-face-desktop clock-face-right-desktop"></div>
                <div
                  className="clock-face-desktop clock-face-back-desktop"
                  data-next="clock-face-bottom-desktop"
                  data-prev="clock-face-top-desktop"
                ></div>
                <div
                  className="clock-face-desktop clock-face-top-desktop"
                  data-next="clock-face-back-desktop"
                  data-prev="clock-face-front-desktop"
                ></div>
              </div>
            </div>
          </LeftWidgetContainer>
          <RightWidgetContainer>
            <div id="content-container-header-clock-right-desktop">
              <div id="clock-seconds-right-desktop">
                <div
                  className="clock-face-right-desktop clock-face-front-right-desktop"
                  data-next="clock-face-top-right-desktop"
                  data-prev="clock-face-bottom-right-desktop"
                ></div>
                <div
                  className="clock-face-right-desktop clock-face-bottom-right-desktop"
                  data-next="clock-face-front-right-desktop"
                  data-prev="clock-face-back-right-desktop"
                ></div>
                <div className="clock-face-right-desktop clock-face-left-right-desktop"></div>
                <div className="clock-face-right-desktop clock-face-right-right-desktop"></div>
                <div
                  className="clock-face-right-desktop clock-face-back-right-desktop"
                  data-next="clock-face-bottom-right-desktop"
                  data-prev="clock-face-top-right-desktop"
                ></div>
                <div
                  className="clock-face-right-desktop clock-face-top-right-desktop"
                  data-next="clock-face-back-right-desktop"
                  data-prev="clock-face-front-right-desktop"
                ></div>
              </div>
            </div>
          </RightWidgetContainer>
        </WidgetContainer>
      ) : (
        <RibbonContainer>{generateIDRibbons()}</RibbonContainer>
      )}
    </CategoriesDesktopContainer>
  );
};
