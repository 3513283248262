import styled from "styled-components";

export const LandingPageTextAnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 16px 0px 16px;
  z-index: 1;
  position: relative;

  .animation-span {
    display: inline-block;
    letter-spacing: 0.5px;
  }

  .normal-font-en {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 0.03em;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 38px;
      line-height: 48px;
    }
    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 28px;
      line-height: 37px;
    }
    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 25px;
      line-height: 34px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .normal-font-id {
    font-family: "Inter";
    font-weight: bold;
    font-size: 34px;
    line-height: 44px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 30px;
      line-height: 41px;
    }
    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 24px;
      line-height: 34px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .normal-font-jp {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 28px;
      line-height: 36px;
    }
    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }
    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
    }
  }

  .bold-font-en {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 50px;
      line-height: 59px;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 46px;
      line-height: 52px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 40px;
      line-height: 46px;
    }
  }

  .bold-font-id {
    font-family: "Inter";
    font-weight: bold;
    font-size: 34px;
    line-height: 44px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 32px;
      line-height: 42px;
    }
    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 30px;
      line-height: 41px;
    }
    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 24px;
      line-height: 34px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .bold-font-jp {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 36px;
      line-height: 44px;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 26px;
      line-height: 34px;
    }
  }

  .first-line-en {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 55px !important;
    line-height: 65px !important;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 50px !important;
      line-height: 60px !important;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 50px !important;
      line-height: 59px !important;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 46px !important;
      line-height: 52px !important;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 40px !important;
      line-height: 46px !important;
    }
  }

  .first-line-id-ID {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 55px !important;
    line-height: 65px !important;
    letter-spacing: 1px;
    color: #ffffff;
    @media screen and (min-width: 1060px) and (max-width: 1260px) {
      font-size: 50px !important;
      line-height: 60px !important;
    }

    @media screen and (min-width: 520px) and (max-width: 992px) {
      font-size: 50px !important;
      line-height: 59px !important;
    }

    @media screen and (min-width: 380px) and (max-width: 520px) {
      font-size: 46px !important;
      line-height: 52px !important;
    }

    @media screen and (min-width: 280px) and (max-width: 380px) {
      font-size: 40px !important;
      line-height: 46px !important;
    }
  }

  .first-line-id {
    /* margin-top: 36px; */
  }
`;
