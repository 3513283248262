import styled from "styled-components";

interface CategoriesDesktopContainerProps {
  background?: string;
  zIndex?: number | string;
}

export const CategoriesDesktopContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerBackground = styled.div<CategoriesDesktopContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: ${(props) => props.zIndex || 0};
  opacity: 0;
`;

export const MobileOutline = styled.img`
  position: absolute;
  width: 38vh;
  z-index: 98;
  margin-top: 4.5vh;
  margin-left: 0vh;
`;

export const LockscreenImage = styled.picture<any>`
  position: absolute;
  width: 33.75vh;
  z-index: ${(props) => props.zIndex};
  img {
    width: 35.9vh;
    border-radius: 4vh;
    margin-top: 5vh;
    margin-left: -1.1vh;
  }
`;

export const Content = styled.div<any>`
  ${(props) => props.styles}
  z-index: ${(props) => props.zIndex};
`;

export const TimeSection = styled.div`
  position: absolute;
  margin-bottom: 64vh;
  /* width: 10vh; */
  z-index: 198;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .lock-img {
    margin-top: 6rem;
    width: 16px;
    transform: translateY(0px);
  }
  .time-detail {
    .time {
      font-size: 42px;
      font-family: "Inter";
    }
    .date {
      font-size: 11px;
      font-family: "Inter";
      text-align: center;
    }
  }
`;

export const WidgetContainer = styled.div`
  position: absolute;
  width: 34vh;
  overflow: hidden;
  min-height: 12vh;
  z-index: 10;
  display: flex;
  /* justify-content: space-between; */
  z-index: 220;
  /* top: 74vh;
  left: 37.25vh; */
  /* transform: translate(-2.25vh); */
  transform: translate(0.3vh, 30vh);
`;

export const LeftWidgetContainer = styled.div<any>`
  width: 9vh;
  height: 9vh;
  #content-container-header-clock-desktop {
    height: 100%;
    width: 9vh;
    float: right;
    padding: 5px;
    perspective: 400;
    -webkit-perspective: 400;
    color: white;
  }

  #clock-seconds-desktop {
    width: 9vh;
    height: 9vh;
    float: left;
    transform-style: preserve-3d;
  }

  .clock-face-desktop {
    z-index: 100;
    position: absolute;
    width: 9vh;
    height: 9vh;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 32px;
    text-align: center;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
  }

  .clock-face-front-desktop {
    transform: translate3d(0vh, 0, 4vh);
  }

  .clock-face-back-desktop {
    transform: rotate3d(0, 1, 0, 180deg) rotate3d(0, 0, 1, 180deg)
      translate3d(0vh, 0, 4vh);
  }

  .clock-face-bottom-desktop {
    transform: rotate3d(1, 0, 0, -90deg) translate3d(0vh, 0, 4vh);
  }

  .clock-face-top-desktop {
    transform: rotate3d(1, 0, 0, 90deg) translate3d(0vh, 0, 4vh);
  }

  .clock-face-right-desktop {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, 90deg) translate3d(0vh, 0, 4vh);
  }

  .clock-face-left-desktop {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, -90deg) translate3d(0vh, 0, 4vh);
  }
`;

export const RightWidgetContainer = styled.div<any>`
  /* width: 45vw; */
  height: 9vh;
  transform: translateX(4vh);
  #content-container-header-clock-right-desktop {
    height: 100%;
    width: 174px;
    float: right;
    padding: 5px;
    perspective: 400;
    -webkit-perspective: 400;
    color: white;
  }

  #clock-seconds-right-desktop {
    /* width: 45vw; */
    height: 9vh;
    float: left;
    transform-style: preserve-3d;
  }

  .clock-face-right-desktop {
    z-index: 100;
    position: absolute;
    width: 19.5vh;
    height: 9vh;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 32px;
    text-align: center;
    -webkit-backface-visibility: hidden; /* Chrome, Safari, Opera */
    backface-visibility: hidden;
  }

  .clock-face-front-right-desktop {
    transform: translate3d(-1vh, 0, 4vh);
  }

  .clock-face-back-right-desktop {
    transform: rotate3d(0, 1, 0, 180deg) rotate3d(0, 0, 1, 180deg)
      translate3d(-1vh, 0, 4vh);
  }

  .clock-face-bottom-right-desktop {
    transform: rotate3d(1, 0, 0, -90deg) translate3d(-1vh, 0, 4vh);
  }

  .clock-face-top-right-desktop {
    transform: rotate3d(1, 0, 0, 90deg) translate3d(-1vh, 0, 4vh);
  }

  .clock-face-right-right-desktop {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, 90deg) translate3d(-1vh, 0, 4vh);
  }

  .clock-face-left-right-desktop {
    background-color: rgba(0, 0, 0, 0.9);
    transform: rotate3d(0, 1, 0, -90deg) translate3d(-1vh, 0, 4vh);
  }
`;

export const RibbonContainer = styled.div`
  position: absolute;
  height: 10vh;
  width: 34vh;
  z-index: 200;
  transform: translate(-1vh, 26vh);
  overflow: hidden;
`;

export const Ribbon = styled.img`
  width: 100%;
  position: absolute;
`;
